import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import bartoszJpg from '../images/bartosz.jpg'
import martaJpg from '../images/marta.jpg'
import paulinaJpg from '../images/paulina.jpg'
import stasiekJpg from '../images/stasiek.jpg'
import weronikaJpg from '../images/weronika.jpg'

const IndexPage = () => (
  <>
    <SEO
      title="Prowadzący zajęcia - MusicSpot"
      keywords={[
        `bartosz goliński`,
        `goliński`,
        `marta kita`,
        `music spot`,
        `guitar lessons`,
        `szkoła muzyczna`,
        `nauka gry na gitarze`,
        `nauka śpiewu`,
      ]}
    />
    <section className="sub-page-header">
      <h1>Prowadzący Zajęcia</h1>
    </section>
    <section
      className="wrapper"
      style={{
        fontSize: '18px',
        lineHeight: '25px',
        color: '#434343',
        textAlign: 'justify',
        padding: '20px',
      }}
    >
      <p>
      Jesteśmy zespołem doświadczonych muzyków, którzy zarówno grają na scenach, jak i uczą muzyki. Nasze umiejętności opierają się na jazzie i muzyce rozrywkowej, ale co ważniejsze, mamy długoletnie doświadczenie jako nauczyciele.
      </p>
      <p>
      Jako aktywni muzycy nieustannie rozwijamy naszą wiedzę i umiejętności na licznych warsztatach, seminariach i kursach mistrzowskich. Naszym celem jest dzielenie się tą wiedzą i doświadczeniem z naszymi uczniami, aby pomóc im osiągnąć sukces w świecie muzyki.
      </p>
    </section>
    <section className="musician">
      <div className="musician__image">
        <img src={bartoszJpg} alt="Bartosz Goliński" />
      </div>
      <div className="musician__description" style={{
    fontSize: '18px',  
    color: '#434343',
    textAlign: 'justify',
    padding: '20px',
  }}>
        <h3>Bartosz Goliński</h3>
        
        <p style={{ lineHeight: '25px' }}>
          gitarzysta, kompozytor, pedagog. Pierwsze kroki jako gitarzysta
          stawiał w szkole muzycznej im. Grażyny Bacewicz w Jaworznie. Absolwent
          Akademii Muzycznej w Katowicach oraz w Poznaniu na kierunku Jazz i
          Muzyka Estradowa. Współpracował z Teatrem Rozrywki w Chorzowie oraz
          orkiestrą Em Band. W roku 2013 został laureatem Festiwalu Gitary
          Elektrycznej oraz przeglądu Imielin Blues Festival.
        </p>
        <p style={{ lineHeight: '25px' }}>
          Poza wykształceniem i doświadczeniem zdobytym na najlepszych
          uczelniach jazzowych w kraju, regularnie szkoli się pod okiem
          wykładowców szkół takich jak: Berklee College of Music (Boston, USA),
          czy New School (Nowy Jork, USA) oraz obecnie czołowych jazzowych
          gitarzystów takich jak: David Dorůžka, Gilad Hekselman, Mike Moreno.
          Jest również uczestnikiem kursów mistrzowskich z udziałem artystów:
          Scott Henderson, Linda Oh, Melissa Aldana, Brad Shepik, Victor Lewis,
          Wallace Roney, Marek Dykta, Grzegorz Kapołka.
        </p>
      </div>
    </section>

    <section className="musician">
      <div className="musician__image">
        <img src={martaJpg} alt="Marta Kita" />
      </div>
      <div className="musician__description" style={{
    fontSize: '18px',
    color: '#434343',
    textAlign: 'justify',
    padding: '20px',
  }}>
        <h3>Marta Kita</h3>
        <p style={{ lineHeight: '25px' }}>
          Jestem absolwentką uczelni muzycznych i programów kursów mistrzowskich dotyczących muzyki jazzowej i rozrywkowej. Moje wykształcenie muzyczne obejmuje studia wokalistyki jazzowej na Krakowskiej Szkole Jazzu i Muzyki Rozrywkowej oraz na wydziale Kompozycji, Interpretacji, Edukacji i Jazzu Akademii Muzycznej w Katowicach. Ponadto, zdobyłam wykształcenie klasyczne w grze na klarnecie w Państwowej Szkole Muzycznej w Chorzowie.
        </p>

        <p style={{ lineHeight: '25px' }}>
        Moje umiejętności stale ewoluują dzięki udziałowi w licznych zagranicznych kursach mistrzowskich, prowadzonych m.in. przez takie wybitne postaci jak Bob Stoloff, Kurt Elling czy Kevin Mahogany. Brałam również udział w wielu warsztatach jazzowych, podczas których miałam przyjemność reprezentować Berklee College of Music na prestiżowym festiwalu Umbria Jazz we Włoszech, a także uczyć się od wybitnych muzyków, takich jak: Mike Moreno, Adam Nussbaum, Joey Blake, Donna McElroy czy Linda May Han Oh. Uczestniczyłam także w warsztatach organizowanych przez instytucje takie jak Berklee College of Music (USA), Newpark Music Center (Irlandia) czy Prins Claus Conservatoire (Holandia). 
        </p>
        <p style={{ lineHeight: '25px' }}>
        Szczególnie istotne dla mnie były warsztaty masterclass prowadzone przez Barry’ego Harrisa.
        </p>
        <p style={{ lineHeight: '25px' }}>
        Wiedzę z zakresu emisji głosu wzbogaciłam również podczas uczestnictwa i współorganizacji międzynarodowych konferencji Naukowcy-Artystom, poświęconych tej tematyce, które miały miejsce w Krakowskiej Akademii Muzycznej. Wśród prelegentów znaleźli się m.in. prof. Krzysztof Izdebski z San Francisco oraz dr Hubert Noe, foniatra i nauczyciel śpiewu w operze wiedeńskiej.
        </p>
        <p style={{ lineHeight: '25px' }}>
        Nadal nieustannie doskonalę swoje umiejętności, głównie pod okiem J.D. Waltera, cenionego wokalisty jazzowego i wykładowcy z Nowego Jorku. 
        </p>
        <p style={{ lineHeight: '25px' }}>
        Moje doświadczenie w nauczaniu sięga już blisko dziesięciu lat. Współpracuję z różnymi uczniami, którym pomagam w przygotowaniach do występów scenicznych, często z programem autorskim oraz jazzowym. Mogę rownież pochwalić się sukcesami moich uczniów, którzy zdali egzaminy na wydziały muzyczne takich uczelni jak London College of Contemporary Music (Wielka Brytania), Carlow College of Music (Irlandia) czy TU Dublin Conservatoire (Irlandia).
        </p>
        <p style={{ lineHeight: '25px' }}>
        Jestem także autorką publikacji w magazynach muzycznych Jazz Forum i JazzPress, zaś wykształcenie w zakresie filologii angielskiej stanowi dodatkowe wsparcie, które pomaga mi w pracy nad repertuarem anglojęzycznym.
        </p>
      </div>
    </section>
    <section className="musician">
      <div className="musician__image">
        <img src={paulinaJpg} alt="Paulina Gacek-Karaś" />
      </div>
      <div className="musician__description" style={{
    fontSize: '18px',  
    color: '#434343',
    textAlign: 'justify',
    padding: '20px',
  }}>
        <h3 style={{ marginBottom: '10px' }}>Paulina Gacek-Karaś</h3>
        <p style={{ lineHeight: '25px' }}>
        Wokalistka, oboistka, lingwistka, instruktor zajęć artystycznych. Absolwentka Akademii muzycznej w Katowicach i w Poznaniu, a także Art Of Voice Studio. 
        </p>
        <p style={{ lineHeight: '25px' }}>
          Ukończyła również Filologię Polska – Dziennikarstwo w kręgu kultury i Filologię Germańską na Uniwersytecie Śląskim. Swój warsztat wokalny szkoliła pod okiem takich nauczycieli jak Ewa Nawrot, Danuta Sendecka, Krystyna Prońko, Anna Gadt, Gabriela Gąsior.
        </p>
        <p style={{ lineHeight: '25px' }}>
        Poszerzała swoje horyzonty wokalne poprzez uczestnictwo w licznych warsztatach, m.in. Warsztaty organizowane przez Stowarzyszenie Wokalistów MIX, Voicingers, Międzynarodowe Leszczyńskie Warsztaty Jazzowe, Poluzone, Warsztaty muzyczne z New Life’m.
        </p>
        <p style={{ lineHeight: '25px' }}>
        Razem z różnymi składami dawała koncerty w Polsce, Francji, Włoszech, odbyła kilkumiesięczną trasę koncertową po Niemczech tworząc „The spectacular night of Queen”, była częścią zespołu „Siedem Siedem”. Współpracowała z Wrocławską Orkiestrą Młodzieżową, Projektem „Mozart 2011”, International Lutosławski Youth Orchestra, Filharmonią Sinfonia Baltica, Orkiestrą Miasta Oświęcim, 
        </p>
        <p style={{ lineHeight: '25px' }}>
        Zdobywała nagrody na konkursach wokalnych na szczeblu ogólnopolskim. Teraz tę tradycję kontynuują jej wychowankowie, zdobywając liczne nagrody na regionalnych konkursach.
        </p>
      </div>
    </section>
    <section className="musician">
      <div className="musician__image">
        <img src={stasiekJpg} alt="Stanisław Ruszkowski" />
      </div>
      <div className="musician__description" style={{
    fontSize: '18px',  
    color: '#434343',
    textAlign: 'justify',
    padding: '20px',
  }}>
        <h3 style={{ marginBottom: '10px' }}>Stanisław Ruszkowski</h3>
        <p style={{ lineHeight: '25px' }}>
        Jestem absolwentem Państwowej Szkoły Muzycznej II stopnia w Poznaniu. Obecnie studiuję na Akademii Muzycznej w Katowicach gdzie rozwijam swoją pasję do gitary w klasie prof. Wandy Palacz. Dzięki studiom mam też regularną możliwość czerpania wiedzy od światowych gwiazd gitary takich jak Adriano del Sal, Marcin Dylla, Alex Garrobe, Adam del Monte. 
        </p>

        <p style={{ lineHeight: '25px' }}>
        Gram na gitarze klasycznej, elektrycznej, basowej, akustycznej i wszystkim innym co pozwala szarpać struny. W czasie wolnym od gitary zajmuję się również aranżacją i produkcją muzyki.
        </p>
        <p style={{ lineHeight: '25px' }}>
        Jestem wielokrotnym stypendystą Miasta Poznania. W czasach szkolnych byłem podopiecznym Krajowego Funduszu na Rzecz Dzieci Zdolnych pod patronatem Pierwszej Damy RP.
        </p>
        <p style={{ lineHeight: '25px' }}>
        Koncertowałem solo i w wielu zespołach m.in. w Zamku Królewskim oraz Łazienkach Królewskich w Warszawie, a także z orkiestrą symfoniczną w Auli Uniwersyteckiej w Poznaniu.
        Jestem laureatem krajowych i międzynarodowych konkursów gitarowych. Między innymi: 
        </p>
        <p>
        - I nagroda – Music Competition Paris 2021,
        </p>
        <p>
        - I nagroda – International Moscow Music Competition 2020,
        </p>
        <p>
        - I nagroda – Kłodzki Festiwal gitarowy GITARIADA 2022,
        </p>
        <p>
        - I nagroda – Summer Guitar Festival Krzyżowa 2022,
        </p>
        <p>
        - I nagroda – XXVI Słupeckie Spotkanina Muzyczne 2021,
        </p>
        <p>
        - I nagorda – IV International Competition of arts Odessa 2020.
        </p>
        <p style={{ lineHeight: '25px' }}>
        Nieustannie staram się rozwijać swoje umiejętności uczestnicząc w kursach muzycznych, takich jak Summer Guitar Festival w Krzyżowej, czy Ogólnopolskie Warsztaty Gitarowe w Krzeszowicach. Nawiązałem dzięki temu wiele znajomości i zostałem doceniony przez gwiazdy gitarystyki.
        </p>
      </div>
    </section>
    <section className="musician">
      <div className="musician__image">
        <img src={weronikaJpg} alt="Weronika Sabatowska" />
      </div>
     <div className="musician__description" style={{
    fontSize: '18px',
    color: '#434343',
    textAlign: 'justify',
    padding: '20px',
  }}>
        <h3 style={{ marginBottom: '10px' }}>Weronika Sabatowska</h3>
        <p style={{ lineHeight: '25px' }}>
          Studentka wokalistyki jazzowej Akademii Muzycznej im. K. Szymanowskiego w Katowicach w klasie mgr Beaty Bednarz. W 2021 roku jej wykonanie „Cóż wiemy o miłości” zostało wyróżnione przez zespół Trubadurzy w programie „Szansa na sukces”. Od dziecka związana z muzyką Gospel (Gospel Life, Joyful Gospel). 
        </p>
        <p style={{ lineHeight: '25px' }}>
        Występowała na scenach: Vertigo Jazz Club, Narodowe Forum Muzyki, Hala Stulecia, rynek wrocławki, opera wrocławska. Aktualnie zajmuje się prowadzeniem zajęć wokalnych oraz pracuje nad autorskim materiałem.
        </p>
      </div>
    </section>
  </>
)

export default IndexPage
